import { graphql } from 'gatsby'
import React from 'react'
import Box from '../components/fragments/Box'
import Layout from '../components/Layout'
import SEO from '../components/seo/Seo'

const search = ({ data }: { data: any }) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchResults, setSearchResults] = React.useState([])
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value)
  }
  React.useEffect(() => {
    const results = data.allGoogleDocs.nodes.filter(e =>
      (e.name + e.description).toLowerCase().includes(searchTerm)
    )
    setSearchResults(results)
  }, [searchTerm])

  return (
    <Layout
      title='Search'
      subtitle='your favorite toy is just around the corner'
    >
      <SEO
        title='TOYREADS - Search for Articles'
        pathname='search'
        desc='your favorite toy is just around the corner'
      />

      <div className='field'>
        <div className='control'>
          <input
            className='input is-large is-primary'
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={handleChange}
          />
        </div>

        {searchResults.map((e: any, i) => (
          <div key={i} className='column is-full'>
            <Box
              title={e.name}
              authorID={e.authorID}
              description={e.description}
              image={e.cover}
              path={e.path}
              date={e.createdTime}
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}
export const fetchLatestBlogPaginatedQuery = graphql`
  query allMetaForSearch {
    allGoogleDocs {
      nodes {
        name
        authorID
        description
        createdTime(fromNow: true)
        path
        cover {
          image {
            childImageSharp {
              fluid(maxWidth: 128) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default search
